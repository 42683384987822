import { useQuery } from '@apollo/client';
import { practitionerQueries } from 'shared-library';

export const useGetListOfPractitioners = () => {

  const { refetch: getListOfPractitioners } = useQuery(practitionerQueries.PRACTITIONER_LIST_SELECT_INFO, {
    fetchPolicy: 'no-cache',
    skip: true,
  });
  return { getListOfPractitioners };
};
