import { ApolloProvider, useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getApolloClient,
  getPractitionerSelectOptions,
  practitionerQueries,
  types,
  useDebounce
} from 'shared-library';
import { getHpdBackendUrl, getHpdUrl } from 'packages/locations';
import { buildStudySelector } from '../../store/studies/selectors';
import { AdditionalPractitioners } from './AdditionalPractitionersList';
import { PrimaryPractitioner } from './PrimaryPractitioner';
import { ReferringPractitioner } from './ReferringPractitioner';
import { isSameLocationNameForPractitioners } from './utils';
import { useGetListOfPractitioners } from './hooks';
import ContactPractitionerAlert from './ContactPractitionerAlert';
import { buildPatientSelector } from '../../store/patients/selectors';

const HealthPractitionerTab = () => {
  const [hpdUrl] = useState(`${getHpdUrl()}/add-practitioner`);
  const [showDifferentLocationWarning, setShowDifferentLocationWarning] = useState(false);
  const { studyId: selectedStudyId } = useParams<{ studyId: string }>();

  const { getListOfPractitioners } = useGetListOfPractitioners();
  const { refetch: getPractitionerData } = useQuery(practitionerQueries.PRACTITIONERS_DETAILS, {
    skip: true,
  });

  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const patient = useSelector(buildPatientSelector(study.patient));

  useEffect(() => {
    setShowDifferentLocationWarning(isSameLocationNameForPractitioners(study));
  }, [study]);

  const getPractitioners = async (input: string) => {
    if (input && input.trim().length < 3) {
      return [];
    }

    const practitioners = await getListOfPractitioners({ search: input });
    return getPractitionerSelectOptions(practitioners.data);
  };


  const loadPractitioners = useDebounce(getPractitioners);

  const findPractitionerAndLocation = async (providerId: string, locationId?: string) => {
    if (providerId) {
      try {
        const { data: practitionerData } = await getPractitionerData({
          id: providerId,
        });

        if (locationId) {
          const practice = practitionerData.practitioner?.locations?.edges.find(
            ({ node }) => node?.id === locationId,
          );
          return { ...practitionerData.practitioner, locations: { edges: [practice] } };
        }

        return practitionerData.practitioner;
      } catch (e) {
        console.error(e.message);
      }
    }
  };

  const fetchPractitioner = (option?: types.Option) => {
    if (option && option.value) {
      return findPractitionerAndLocation(option.value.split('|')[0], option.value.split('|')[1]);
    }

    return null;
  };

  const additionalPractitioners =
    study.practitioners?.additional?.map(({ practitionerID, locationID }) =>
      findPractitionerAndLocation(practitionerID, locationID),
    ) || [];

  const showOnlineBookingReferrerAlert =
    patient.onlineBookingReferrer?.referrerName &&
    patient.onlineBookingReferrer?.referrerPhoneNumber &&
    !study.practitioners?.referring?.practitionerID;

  return (
    <>
      {showOnlineBookingReferrerAlert && (
        <ContactPractitionerAlert
          referrerName={patient.onlineBookingReferrer.referrerName}
          referrerPhoneNumber={patient.onlineBookingReferrer.referrerPhoneNumber}
        />
      )}

      <ReferringPractitioner
        providerID={study.practitioners?.referring?.practitionerID}
        loadPractitioners={loadPractitioners}
        fetchPractitioner={fetchPractitioner}
        hpdUrl={hpdUrl}
        showDifferentLocationWarning={showDifferentLocationWarning}
      />

      <PrimaryPractitioner
        providerID={study.practitioners?.primary?.practitionerID}
        loadPractitioners={loadPractitioners}
        fetchPractitioner={fetchPractitioner}
        hpdUrl={hpdUrl}
        showDifferentLocationWarning={showDifferentLocationWarning}
      />

      <AdditionalPractitioners
        providers={additionalPractitioners}
        loadPractitioners={loadPractitioners}
        fetchPractitioner={fetchPractitioner}
        hpdUrl={hpdUrl}
      />
    </>
  );
};

const HealthPractitionerWithApollo = () => {
  const [apiEndpoint] = useState(getHpdBackendUrl());
  const apolloClient = getApolloClient(apiEndpoint);
  return useMemo(() => {
    return (
      <>
        {apiEndpoint && (
          <ApolloProvider client={apolloClient}>
            <HealthPractitionerTab />
          </ApolloProvider>
        )}
      </>
    );
  }, []);
};

export default HealthPractitionerWithApollo;
