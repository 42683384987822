import React from 'react';
import { Section } from 'shared-library';
import { formatCapitalizedFirstLetter } from 'packages/formatters';

const ErrorLoadingPractitioner = ({ practitionerType }: { practitionerType: string }) => (
  <Section
    title={`${formatCapitalizedFirstLetter(practitionerType)} Practitioner`}
    disabled={false}
  >
    {`There was an error loading the ${practitionerType} practitioner's information. Please try
    refreshing the page or report an issue.`}
  </Section>
);

export default ErrorLoadingPractitioner;
