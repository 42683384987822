import { useMutation, useQuery } from '@apollo/client';
import { fetchAppsResource } from 'packages/apis';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  FrontdeskPractitionerForm,
  LoadingSpinner,
  practitionerQueries,
  practitionerMutations,
  Section,
  types,
  getContactAndLocationInformation,
  mapPractitionerFormData,
} from 'shared-library';
import { buildStudySelector } from '../../store/studies/selectors';
import ErrorLoadingPractitioner from './ErrorLoadingPractitioner';
import { FrontdeskPractitionerProps } from './types';
import { addPractitionerToStore, getPractitionerLocationId } from './utils';
import { removeHealthPractitioner } from '../../store/studies/actions';

export const PrimaryPractitioner = ({
  providerID,
  loadPractitioners,
  fetchPractitioner,
  hpdUrl,
  showDifferentLocationWarning,
}: FrontdeskPractitionerProps): React.ReactElement => {
  const dispatch = useDispatch();

  const [editing, setEditing] = useState(false);
  const { studyId } = useParams<{ studyId: string }>();

  const selectStudy = buildStudySelector(studyId);
  const study = useSelector(selectStudy);

  const loading = !study?.practitioners;

  const [
    selectedPractitioner,
    setSelectedPractitioner,
  ] = useState<types.FrontdeskPractitionerFormInputs | null>();

  const {
    loading: practitionerDetailsLoading,
    error: practitionerDetailsError,
    data: practitionerDetails,
  } = useQuery(practitionerQueries.PRACTITIONERS_DETAILS, {
    variables: {
      id: study?.practitioners?.primary?.practitionerID,
    },
    skip: !study?.practitioners?.primary?.practitionerID,
  });

  useEffect(() => {
    if (practitionerDetails && practitionerDetails?.practitioner?.id !== selectedPractitioner?.id) {
      const practice = practitionerDetails?.practitioner?.locations?.edges.find(
        ({ node }) => node?.id === study?.practitioners?.primary?.locationID,
      );
      setSelectedPractitioner({
        ...practitionerDetails.practitioner,
        locations: { edges: [practice] },
      });
    }
  }, [practitionerDetails]);

  const changePrimary = (practitioner: types.FrontdeskPractitionerFormInputs) => {
    try {
      fetchAppsResource(`/study/${studyId}/health-practitioner`, 'POST', {
        type: 'primary',
        providerId: practitioner.id,
        prenuvoId: practitioner.prenuvoId,
        action: 'add',
        locationId: getPractitionerLocationId(practitioner),
      });
      addPractitionerToStore(practitioner, dispatch, study.studyID, 'primary');
    } catch (error) {
      console.error(error.message);
    }
  };

  const [addPractitionerMutation] = useMutation(practitionerMutations.ADD_PRACTITIONER);

  if (loading && providerID && practitionerDetailsLoading) {
    return <LoadingSpinner size="small" />;
  }

  if (practitionerDetailsError && !!providerID) {
    return <ErrorLoadingPractitioner practitionerType="Primary" />;
  }

  const addPractitioner = async (data) => {
    try {
      const { practitioner } = mapPractitionerFormData(data);

      const { data } = await addPractitionerMutation({
        variables: {
          practitioner,
        },
      });

      const newPractitioner = data?.createPractitioner?.practitioner;

      if (newPractitioner) {
        setSelectedPractitioner(newPractitioner);
        addPractitionerToStore(newPractitioner, dispatch, study.studyID, 'primary');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = async (data) => {
    try {
      await addPractitioner(data);
    } catch (e) {
      console.log(e);
    }

    setEditing(false);
  };

  const onEditClickPrimary = () => {
    setEditing(!editing);
  };

  const onCloseClick = () => {
    setEditing(false);
  };

  const onClickAdd = async (input) => {
    const practitioner = await fetchPractitioner(input);

    setSelectedPractitioner(practitioner);
    changePrimary(practitioner);

    setEditing(false);
  };

  const onClickRemove = () => {
    try {
      removeHealthPractitioner(
        studyId,
        selectedPractitioner.id,
        'primary',
        selectedPractitioner,
      )(dispatch);
      setSelectedPractitioner(null);
      onEditClickPrimary();
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <Section
      title="Primary Health Practitioner"
      onEditClick={onEditClickPrimary}
      viewState={editing ? 'editing' : 'edit'}
      disabled={false}
      onCloseClick={editing ? onCloseClick : null}
    >
      <FrontdeskPractitionerForm
        values={
          selectedPractitioner
            ? getContactAndLocationInformation(
                selectedPractitioner,
                study?.practitioners?.primary?.locationID || '',
              )
            : {}
        }
        editing={editing}
        onSubmit={onSubmit}
        onCancel={onEditClickPrimary}
        loadPractitioners={loadPractitioners}
        onClickAdd={onClickAdd}
        selectedPractitioner={(!editing && selectedPractitioner) || null}
        mode="primary"
        hpdUrl={hpdUrl}
        showDifferentLocationWarning={showDifferentLocationWarning}
        onRemove={selectedPractitioner && onClickRemove}
      />
    </Section>
  );
};
