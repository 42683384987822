import { types } from 'shared-library';
import { updatePractitionerAction } from '../../store/studies/actions';
import { StudyState } from '../../store/studies/types';

export const isSameLocationNameForPractitioners = (study: StudyState): boolean => {
  const referralPractitioner = study?.practitioners?.referring;
  const primaryPractitioner = study?.practitioners?.primary;

  if (primaryPractitioner?.practitionerID !== referralPractitioner?.practitionerID) {
    return false;
  }
  return referralPractitioner?.locationID !== primaryPractitioner?.locationID;
};

export const getPractitionerLocationId = (practitioner: types.FrontdeskPractitionerFormInputs): string => {
  return practitioner?.locations?.edges?.length > 0
    ? practitioner.locations.edges[0].node.id
    : undefined;
};

export const addPractitionerToStore = (
  practitioner: types.FrontdeskPractitionerFormInputs,
  dispatch,
  studyId,
  type,
): void => {
  const referredPractitioner = {
    practitionerID: practitioner.id,
    prenuvoID: practitioner.prenuvoId,
    locationID: getPractitionerLocationId(practitioner),
  };

  dispatch(updatePractitionerAction(studyId, referredPractitioner, type));
};
