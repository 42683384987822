import { useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';

import {
  FrontdeskPractitionerForm,
  LoadingSpinner,
  practitionerQueries,
  getContactAndLocationInformation,
  types,
} from 'shared-library';

import ErrorLoadingPractitioner from './ErrorLoadingPractitioner';
import { AdditionalPractitionerProps } from './types';

export const AdditionalPractitioner = ({
  practitionerID,
  locationID,
  onRemove,
  editing,
}: AdditionalPractitionerProps): React.ReactElement => {
  const [practitioner, setPractitioner] = useState<types.FrontdeskPractitionerFormInputs | null>();

  const {
    loading: practitionerDetailsLoading,
    error: practitionerDetailsError,
    data: practitionerDetails,
  } = useQuery(practitionerQueries.PRACTITIONERS_DETAILS, {
    variables: {
      id: practitionerID,
    },
    skip: !practitionerID,
  });

  useEffect(() => {
    if (practitionerDetails && practitionerDetails?.practitioner?.id !== practitioner?.id) {
      const practice = practitionerDetails?.practitioner?.locations?.edges.find(
        ({ node }) => node?.id === locationID,
      );
      setPractitioner({
        ...practitionerDetails.practitioner,
        locations: { edges: [practice] },
      });
    }
  }, [practitionerDetails]);

  if (practitionerDetailsLoading) {
    return <LoadingSpinner size="small" />;
  }

  if (practitionerDetailsError) {
    return <ErrorLoadingPractitioner practitionerType="Additional" />;
  }

  return (
    <FrontdeskPractitionerForm
      editing={false}
      mode="additional"
      values={practitioner ? getContactAndLocationInformation(practitioner, locationID || '') : {}}
      onRemove={editing ? () => onRemove(practitionerID) : null}
      key={`additional-practitioner-${practitionerID}`}
      isMulti={false}
    />
  );
};
