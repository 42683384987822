import { useQuery } from '@apollo/client';
import { fetchAppsResource } from 'packages/apis';
import PDFMerger from 'packages/pdf-merger';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  Button,
  LoadingSpinner,
  practitionerQueries,
  ReferringPractitionerForm,
  Section,
  types,
  getContactAndLocationInformation,
  Option,
  Notification,
} from 'shared-library';
import styled from 'styled-components';
import { changeReferredStatus, updateReferralType } from '../../store/studies/actions';
import { buildStudySelector } from '../../store/studies/selectors';
import ChangeReferralModal from './ChangeReferralModal';
import ErrorLoadingPractitioner from './ErrorLoadingPractitioner';
import { FrontdeskPractitionerProps } from './types';
import { addPractitionerToStore, getPractitionerLocationId } from './utils';

const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ReferralType = {
  'Not Required': 'doctor',
  Required: 'prenuvo',
};

export const ReferringPractitioner = ({
  providerID,
  loadPractitioners,
  fetchPractitioner,
  hpdUrl,
  showDifferentLocationWarning,
}: FrontdeskPractitionerProps): React.ReactElement => {
  const { addToast } = useToasts();
  const [changeReferrerModal, setChangeReferrerModal] = useState(false);
  const [editingReferring, setEditingReferring] = useState(false);
  const { studyId } = useParams<{ studyId: string }>();

  const selectStudy = buildStudySelector(studyId);
  const study = useSelector(selectStudy);

  const [
    onAddSelectedPractitioner,
    setOnAddSelectedPractitioner,
  ] = useState<types.FrontdeskPractitionerFormInputs | null>();

  const [loadingStatusChange, setLoadingStatusChange] = useState<boolean>(false);
  const dispatch = useDispatch();

  const loading = !study?.practitioners;

  const [
    selectedPractitioner,
    setSelectedPractitioner,
  ] = useState<types.FrontdeskPractitionerFormInputs | null>();

  const {
    loading: practitionerDetailsLoading,
    error: practitionerDetailsError,
    data: practitionerDetails,
  } = useQuery(practitionerQueries.PRACTITIONERS_DETAILS, {
    variables: {
      id: study?.practitioners?.referring?.practitionerID,
    },
    skip: !study?.practitioners?.referring?.practitionerID,
  });

  const setSelectedPractitionerFromQueryData = () => {
    if (practitionerDetails) {
      const practice = practitionerDetails.practitioner?.locations?.edges.find(
        ({ node }) => node?.id === study?.practitioners?.referring?.locationID,
      );
      setSelectedPractitioner({
        ...practitionerDetails.practitioner,
        locations: { edges: [practice] },
      });
    } else {
      setSelectedPractitioner(null);
    }
  };

  useEffect(() => {
    setSelectedPractitionerFromQueryData();
  }, [practitionerDetails]);

  const changeReferralStatus = async (referred) => {
    setLoadingStatusChange(true);

    await changeReferredStatus(studyId, study.status, referred, () => {
      setLoadingStatusChange(false);
      addToast(
        Notification.create(
          'Updated referral status',
          `Successfully updated health practitioner referring status to ${
            referred ? 'Referred' : 'Pending'
          }`,
        ),
        { appearance: 'success' },
      );
    })(dispatch);
  };

  const changeReferrer = async (practitioner: types.FrontdeskPractitionerFormInputs) => {
    try {
      await changeReferralStatus(false);
      await fetchAppsResource(`/study/${studyId}/health-practitioner`, 'POST', {
        type: 'referring',
        providerId: practitioner.id,
        prenuvoId: practitioner.prenuvoId,
        action: 'add',
        locationId: getPractitionerLocationId(practitioner),
      });
      addPractitionerToStore(practitioner, dispatch, study.studyID, 'referring');
      addToast(
        Notification.create(
          'Referring Practitioner Updated',
          `Successfully updated referring practitioner for study ${study?.studyID}`,
        ),
        { appearance: 'success' },
      );
    } catch (e) {
      addToast(
        Notification.create(
          'Error updating referring practitioner',
          'Something unexpected went wrong when updating health practitioner. Give it another try and please report an issue if this persists.',
        ),
        { appearance: 'error' },
      );
    }
  };

  const changeReferralTypeAction = async (referralType: string) => {
    await updateReferralType(studyId, referralType)(dispatch);
  };

  const changeReferralType = async (referralType: string) => {
    try {
      await fetchAppsResource(`/study/${studyId}/referralType/${referralType}`, 'POST');
      changeReferralTypeAction(referralType);
    } catch (e) {
      addToast(
        Notification.create(
          'Error updating referring practitioner type',
          'Something unexpected went wrong when updating health practitioner. Give it another try and please report an issue if this persists.',
        ),
        { appearance: 'error' },
      );
    }
  };

  if (loading && providerID && practitionerDetailsLoading) {
    return <LoadingSpinner size="small" />;
  }

  if (practitionerDetailsError && !!providerID) {
    return <ErrorLoadingPractitioner practitionerType="Referring" />;
  }

  const onEditClickReferring = () => {
    setEditingReferring(!editingReferring);
  };

  const onCancelEditReferring = () => {
    setEditingReferring(!editingReferring);
    setSelectedPractitionerFromQueryData();
  };

  const onFormSubmit = async (input) => {
    // Checks if new practitioner selected and not the same as the current
    if (selectedPractitioner && selectedPractitioner.id !== providerID) {
      // Checks if there is no referring practitioner for the study
      if (!study?.practitioners?.referring) {
        await changeReferrer(selectedPractitioner);
        setSelectedPractitioner(selectedPractitioner);
      } else {
        setChangeReferrerModal(true);
        setOnAddSelectedPractitioner(selectedPractitioner);
      }
    } else {
      setSelectedPractitionerFromQueryData();
    }

    // Checks if referral type value changes
    const referralType = ReferralType[input.prenuvoReferral];
    if (referralType && referralType !== study?.practitioners?.referralType) {
      await changeReferralType(referralType);
    }

    setEditingReferring(false);
  };

  const changeSelectedPractitioner = async () => {
    await changeReferrer(onAddSelectedPractitioner);
    setChangeReferrerModal(false);
    setSelectedPractitioner(onAddSelectedPractitioner);
    setEditingReferring(false);
  };

  const handleViewReferral = () => {
    const referrerWithContactInformation = getContactAndLocationInformation(
      selectedPractitioner,
      study?.practitioners?.referring?.locationID || '',
    );
    const ReferralMerger = new PDFMerger('medical-referral', [study.patient], studyId, null, {
      profession: referrerWithContactInformation?.profession,
      fullName: `${referrerWithContactInformation?.firstName} ${referrerWithContactInformation?.lastName}`,
      emailAddress: referrerWithContactInformation?.emailAddress,
      phoneNumber: referrerWithContactInformation?.phoneNumber,
    });

    ReferralMerger.downloadPdfAsJpg();
  };

  const onSelectPractitioner = async (option?: Option) => {
    if (option && option.value) {
      const practitioner = await fetchPractitioner(option);
      setSelectedPractitioner(practitioner);
    } else {
      setSelectedPractitioner(null);
    }
  };

  const referringPractitionerData = getContactAndLocationInformation(
    selectedPractitioner,
    study?.practitioners?.referring?.locationID || '',
    study?.practitioners?.referralType,
  );

  const isReferralDenied = !!(!study.status.referred && study.status.referredTime);

  return (
    <Section
      title="Referring Practitioner"
      onEditClick={onEditClickReferring}
      viewState={editingReferring ? 'editing' : 'edit'}
      disabled={false}
      onCloseClick={editingReferring ? onCancelEditReferring : null}
    >
      {changeReferrerModal && (
        <ChangeReferralModal
          changeReferrerModal={() => setChangeReferrerModal(!changeReferrerModal)}
          changeSelectedPractitioner={changeSelectedPractitioner}
        />
      )}

      <ReferringPractitionerForm
        values={referringPractitionerData}
        editing={editingReferring}
        onSubmit={onFormSubmit}
        onCancel={onCancelEditReferring}
        loadPractitioners={loadPractitioners}
        selectedPractitioner={selectedPractitioner}
        hpdUrl={hpdUrl}
        showDifferentLocationWarning={showDifferentLocationWarning}
        onSelectPractitioner={onSelectPractitioner}
      />

      {providerID && !editingReferring && (
        <ButtonDiv>
          <Button variant="secondary" type="button" size="xsmall" onClick={handleViewReferral}>
            View Referral
          </Button>

          <Button
            variant="secondary"
            size="xsmall"
            onClick={() => changeReferralStatus(isReferralDenied ? false : !study.status?.referred)}
          >
            {loadingStatusChange ? (
              <LoadingSpinner size="small" />
            ) : (
              `Set as ${study.status.referred || isReferralDenied ? 'Pending' : 'Referred'}`
            )}
          </Button>
        </ButtonDiv>
      )}
    </Section>
  );
};
