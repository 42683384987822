import React from 'react';
import { Notification, Heading, Text } from 'shared-library';

type ContactPractitionerAlertProps = {
  referrerName: string;
  referrerPhoneNumber: string;
};

const ContactPractitionerAlert: React.FC<ContactPractitionerAlertProps> = ({
  referrerName,
  referrerPhoneNumber,
}: ContactPractitionerAlertProps) => {
  return (
    <Notification appearance="warning" width="100%">
      <Heading size={7}>Action Required</Heading>
      <div>
        <Text>
          Please contact the practitioner below to collect their information. If the practitioner
          does not already exist in our system, please create a new Referring Practitioner.
        </Text>
        <ul>
          <li>
            <Text>
              Practitioner Name: <strong>{referrerName}</strong>
            </Text>
          </li>
          <li>
            <Text>
              Practitioner Phone Number: <strong>{referrerPhoneNumber}</strong>
            </Text>
          </li>
        </ul>
      </div>
    </Notification>
  );
};

export default ContactPractitionerAlert;
