import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  colors,
  FrontdeskPractitionerForm,
  mapPractitionerFormData,
  practitionerMutations,
  Section,
  types,
} from 'shared-library';
import styled from 'styled-components';
import { removeHealthPractitioner, updateHealthPractitioner } from '../../store/studies/actions';
import { buildStudySelector } from '../../store/studies/selectors';
import { AdditionalPractitioner } from './AdditionalPractitioner';
import { getPractitionerLocationId } from './utils';

interface BorderProps {
  editing: boolean;
}

const Border = styled.div<BorderProps>`
${({ editing }) =>
  editing ? `border-top: 1px solid ${colors.smoke};` : '&:first-child {visible: none};'}
  border-bottom: 1px solid ${colors.smoke};
  padding: 30px 0;
`;

export interface AdditionalFrontdeskPractitionerProps {
  providers?: [types.FrontdeskPractitionerFormInputs];
  fetchPractitioner: (Option) => types.FrontdeskPractitionerFormInputs;
  loadPractitioners: (string) => void;
  hpdUrl?: string;
}

export const AdditionalPractitioners = ({
  providers,
  fetchPractitioner,
  loadPractitioners,
  hpdUrl,
}: AdditionalFrontdeskPractitionerProps) => {
  const dispatch = useDispatch();

  const [
    selectedPractitioner,
    setSelectedPractitioner,
  ] = useState<types.FrontdeskPractitionerFormInputs | null>();
  const [practitioners, setPractitioners] = useState(providers || []);

  const [editing, setEditing] = useState(false);
  const onEditClick = () => {
    setEditing(!editing);
  };

  const { studyId: selectedStudyId } = useParams();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);

  const [addPractitionerMutation] = useMutation(practitionerMutations.ADD_PRACTITIONER);

  const onCancel = () => {
    setSelectedPractitioner(null);
  };

  const addAdditionalPractitioner = async (additionalPractitioners) => {
    const formattedPractitioners = additionalPractitioners.map((practitioner) => ({
      practitionerID: practitioner.id,
      prenuvoID: practitioner.prenuvoId,
      locationID: getPractitionerLocationId(practitioner),
    }));

    await updateHealthPractitioner(
      study.studyID,
      'additional',
      study.practitioners.additional,
      formattedPractitioners,
    )(dispatch);
  };

  const addPractitioner = async (data) => {
    if (selectedPractitioner?.id) {
      return selectedPractitioner.id;
    }

    try {
      const { practitioner } = mapPractitionerFormData(data);

      const res = await addPractitionerMutation({
        variables: {
          practitioner,
        },
      });

      const newPractitioner = res?.data?.createPractitioner?.practitioner;

      if (newPractitioner) {
        setPractitioners([...practitioners, newPractitioner]);
        addAdditionalPractitioner([newPractitioner]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onRemove = async (practitionerId: string) => {
    const filteredPractitioners = study.practitioners.additional.filter(
      ({ practitionerID }) => practitionerID !== practitionerId,
    );
    await removeHealthPractitioner(
      study.studyID,
      practitionerId,
      'additional',
      filteredPractitioners,
    )(dispatch);
    setPractitioners(filteredPractitioners || []);
  };

  const onSelectPractitioner = async (options?: Array<types.Option>) => {
    if (options) {
      const newPractitioners = [];

      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < options.length; index++) {
        // eslint-disable-next-line no-await-in-loop
        const practitionerToAdd = await fetchPractitioner(options[index]);
        newPractitioners.push(practitionerToAdd);
      }

      setSelectedPractitioner(null);
      setPractitioners(newPractitioners || []);
    }
  };

  const onCloseClick = () => {
    setEditing(false);
  };

  const onClickAdd = () => {
    addAdditionalPractitioner(practitioners);
    onCloseClick();
  };

  return (
    <>
      <Section
        title="Additional Health Practitioners"
        disabled={false}
        viewState={editing ? 'editing' : 'edit'}
        onEditClick={onEditClick}
        onCloseClick={editing ? onCloseClick : null}
      >
        {editing && (
          <FrontdeskPractitionerForm
            editing
            loadPractitioners={loadPractitioners}
            onSelectPractitioner={onSelectPractitioner}
            selectedPractitioner={selectedPractitioner}
            onSubmit={addPractitioner}
            onCancel={onCancel}
            mode="additional"
            hpdUrl={hpdUrl}
            onClickAdd={onClickAdd}
            isMulti
          />
        )}

        {study?.practitioners?.additional?.length > 0 &&
          study.practitioners.additional.map(({ practitionerID, locationID }) => {
            if (!practitionerID) {
              return <div />;
            }

            return (
              <Border key={`border-additional-practitioner-${practitionerID}`} editing={editing}>
                <AdditionalPractitioner
                  practitionerID={practitionerID}
                  locationID={locationID}
                  onRemove={onRemove}
                  editing={editing}
                />
              </Border>
            );
          })}
        {!editing && study?.practitioners?.additional?.length === 0 && (
          <AdditionalPractitioner editing={editing} />
        )}
      </Section>
    </>
  );
};
