import React from 'react';
import styled from 'styled-components';

import { Modal, Text, Button } from 'shared-library';

const ButtonRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 32px;
`;

interface ChangeReferralModalProps {
  changeReferrerModal: () => void;
  changeSelectedPractitioner: (boolean) => void;
}

const ChangeReferralModal = ({
  changeReferrerModal,
  changeSelectedPractitioner,
}: ChangeReferralModalProps) => (
  <Modal title="Are you sure you want to change the referrer?" onClose={changeReferrerModal}>
    <Text align="center">
      Changing the referrer will set the referral status to &quot;Pending&quot;.
    </Text>

    <ButtonRow>
      <Button variant="secondary" size="medium" onClick={changeReferrerModal}>
        No
      </Button>

      <Button variant="primary" size="medium" onClick={changeSelectedPractitioner}>
        Yes
      </Button>
    </ButtonRow>
  </Modal>
);

export default ChangeReferralModal;
